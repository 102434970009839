import { Card } from "components/Card/styled"
import { Content } from "components/Content/styled"
import Modal from 'components/Modal'
import { CardBody } from "components/CardBody/styled"
import { BtnBlue, BtnBox, BtnWhite } from "components/Button/styled"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import Input from 'components/Form/LabelInput'
import * as Yup from 'yup'
import { handleUsersByRoles } from "services/api/users"
import SelectDepartment from "components/Form/SelectDepartment"
import { handleCardRoles, handleCardShow } from "services/api/reportCards"
import Checkbox from 'components/Form/LabelCheckbox'
import api from "services/api/api"
import Messages from 'components/Messages'
import { setErrors } from "utils"
import { Spinner } from "components/Spinner/styled"
import Validation from "./validationAddUsersCards"
import LoadingComponent from "components/Loading"
import Select from 'components/Form/Select'

const ModalAddUser = props =>  {
  const [data, setData] = useState([])
  const [card, setCard] = useState([])
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [reportRoles, setReportRoles] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
          const users = await handleUsersByRoles('user')
          const cardInfo = await handleCardShow(props.reportId)
          const roles = await handleCardRoles(props.reportId);
          setCard(cardInfo)
          setData(users)
          setReportRoles(roles);
        setLoading(false)
      } catch(error) {
      }
    })()
  }, [])

  const formik = useFormik({
    initialValues: {
      user_id: '',
      report_card_id: props.reportId,
      roles: '',
      page_navigation: false,
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: async () => {
      try {
        formik.setFieldValue('user_id', formik.values.user_id.value)
        await addReport(formik.values, setMessages)
        // props.setVisibleModal(false)
        handleResetForm(formik)
      } catch (error) {
        setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        formik.resetForm();
      }
    },
    enableReinitialize: true
  })

  const handleResetForm = (formik) => {
    formik.resetForm();
  };

  const addReport = async (formik, setMessages) => {
    const values = {
      ...formik,
      user_id: formik.user_id.value,
    }
    try {
      await api.post(`/reports/store`, values)
      props.setVisibleModal(false)
    } catch (error) {
      setMessages({ messages: setErrors(error), alert: 'alert-orange' })
    }
  }

    return(
    <Modal visibleModal={props.visibleModal} setVisibleModal={props.setVisibleModal}>
         {loading && <LoadingComponent />}
        {!loading && (
          <>
        <Card>
          <Content>
            <Content>
              
              <CardBody>
              {/* <div style={{marginTop: '20px'}}> */}
                <Messages formMessages={messages.messages} alert={messages.alert} />
              {/* </div> */}
                <p style={{fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '1rem'}}>
                  {props.itemMessage}
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <SelectDepartment
                    name="user_id"
                    label="Usuário"
                    formik={formik}
                    // value={option => formik.setFieldValue('user_id', option.value)}
                    options={data.map(user => ({
                      value: user.id,
                      label: user.name
                    }))}
                    // onChange={option => formik.setFieldValue('user_id', option.value)}
                  />

                  <Select name="roles" label="Roles" formik={formik}
                    options={
                      reportRoles.map(role => ({ value: role, label: role })) 
                    }
                  />
                  <Checkbox name='page_navigation' label='Habilitar Abas' formik={formik} checked={false} />

                  <BtnBox>
                    <BtnBlue type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <Spinner /> : "Enviar"}
                    </BtnBlue>
                    <BtnWhite onClick={() => 
                      // setVisibleModal(false)
                      props.setVisibleModal(false)
                      }>Cancelar</BtnWhite>
                  </BtnBox>
                </form>
              </CardBody>
            </Content>  
          </Content>
        </Card>
        </>
        )}
      </Modal>
    )
}

export default ModalAddUser