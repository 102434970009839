import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import { FaEdit, FaChartArea } from "react-icons/fa"
import Td from 'components/Td'
import { Context } from 'contexts/context'
import { useContext, useState } from 'react'
import { InputCadastrar } from 'pages/Cards/styled'
import InputWithSearchIcon from 'components/InputSearch'
import { TotalUsers } from 'components/Table/styled'
import { CardTitleUsers } from 'components/CardTitle/styled'
import UsersFilters from 'components/UsersFilters'

const Component = props => {
    const { user: { firm_id } } = useContext(Context)

    const [filter, setFilter] = useState('');
    const [activeTab, setActiveTab] = useState('ativos');

    // const filteredData = props.data.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()));

    function includesFilter(value, filter) {
        return value && value.toLowerCase().includes(filter.toLowerCase());
    }

    // ADD NOT INCLUDE DATOTECNOLOGIA EMAILS
    const notDatoEmailOrDatoDiretoria = props.data.filter(item =>
        (firm_id === 11 || firm_id === 18) || 

        ((!item.email.includes('@datotecnologia.com.br') || item.email === 'bi@datotecnologia.com.br') && firm_id !== 11 && firm_id !== 18)
    )

    const filteredData = notDatoEmailOrDatoDiretoria.filter(item =>
        includesFilter(item.name, filter) ||
        includesFilter(item.id.toString(), filter) ||
        includesFilter(item.email, filter) ||
        includesFilter(item.department, filter) ||
        (
            // firm_id === 11
            (firm_id === 11 || firm_id === 19 || firm_id === 20)
         && includesFilter(item.company, filter)) ||
        includesFilter(item.roles, filter)
    );

    const handleNavClick = (tab) => {
        setActiveTab(tab);
        // Add logic to fetch data based on the active tab
      };
    
    return (
        <>
            <TitleBar label="Usuários" currentPage="" />
            <Content>
                <Card>
                    <CardTitleUsers>
                    {/* // title="Listagem"> */}
                    <div style={{display: "flex", flexDirection: "row", padding: "0px"}}>
                        <UsersFilters 
                            onNavClick={handleNavClick} /> 
                    </div>
                        <InputCadastrar >
                            <InputWithSearchIcon 
                            type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                            
                            <LinkBlue to="/usuarios/cadastrar">Cadastrar</LinkBlue>
                        </InputCadastrar>
                        
                    </CardTitleUsers>
                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th >Nome</th>
                                <th>Email</th>
                                <th>Departamento</th>
                                {
                                    // firm_id === 11
                                (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                                && (
                                    <th>Empresa</th>
                                )}
                                <th>Permissão</th>
                                <th>Bloqueado</th>
                                <th width="100">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                        {/* {activeTab === 'favoritos'  && favoritesReports.length > 0 && ( */}
                        {activeTab === 'ativos' && (
                            <>{filteredData
                                .filter(item => item.inactive === 0) // Filtra usuários ativos
                                .map(item =>
                                <tr key={item.id} >
                                    <td style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{`#${item.id}`}</td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.name}</td>
                                    <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.email}</td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.department || "Sem departamento"}</td>
                                    {
                                        // firm_id === 11
                                    (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                                    && (
                                    <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.company || "Sem Empresa cadastrada"}</td>
                                )}
                                    <td style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.roles}</td>
                                    <td>{item.blocked_user ? "Sim" : ""}</td>
                                    <Td>
                                        <SmLinkBlue to={`/usuarios/${item.id}/editar`}>
                                            <FaEdit />
                                        </SmLinkBlue>
                                        {item.roles === 'user' &&
                                            <SmLinkBlue to={`/usuarios/${item.id}/relatorios`}>
                                                <FaChartArea />
                                            </SmLinkBlue>
                                        }
                                    </Td>
                                </tr>
                            )}
                            </>)}
                        
                        {activeTab === 'inativos' && (
                            <>
                                {filteredData
                                    .filter(item => item.inactive === 1) // Filtra usuários inativos
                                    .map(item =>
                                    <tr key={item.id} >
                                        <td style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{`#${item.id}`}</td>
                                        <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.name}</td>
                                        <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.email}</td>
                                        <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.department || "Sem departamento"}</td>
                                        {
                                            // firm_id === 11
                                        (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                                        && (
                                        <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.company || "Sem Empresa cadastrada"}</td>
                                    )}
                                        <td style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.roles}</td>
                                        <td>{item.blocked_user ? "Sim" : ""}</td>
                                        <Td>
                                            <SmLinkBlue to={`/usuarios/${item.id}/editar`}>
                                                <FaEdit />
                                            </SmLinkBlue>
                                            {item.roles === 'user' &&
                                                <SmLinkBlue to={`/usuarios/${item.id}/relatorios`}>
                                                    <FaChartArea />
                                                </SmLinkBlue>
                                            }
                                        </Td>
                                    </tr>
                                )}
                                {filteredData.filter(item => item.inactive === 1).length === 0 && (
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: 'center', fontSize: "20px", fontWeight: "700", padding: "20px" }}>Sem usuários inativos</td>
                                    </tr>
                                )}
                            </>
                        )}
                        
                        </tbody>
                        
                    </Table>
                    <TotalUsers>
                        <span style={{ marginLeft: '20px' }}>Total usuários: {notDatoEmailOrDatoDiretoria.length}</span>
                        <span style={{ margin: '0 20px' }}>|</span>
                        <span>Ativos: {notDatoEmailOrDatoDiretoria.filter(item => item.inactive === 0).length}</span>
                        <span style={{ margin: '0 20px' }}>|</span>
                        <span>Bloqueados: {notDatoEmailOrDatoDiretoria.filter(item => item.blocked_user).length}</span>
                        <span style={{ margin: '0 20px' }}>|</span>
                        <span>Inativos: {notDatoEmailOrDatoDiretoria.filter(item => item.inactive === 1).length}</span>
                    </TotalUsers>
                </Card>
            </Content>
        </>
    )
}

export default Component