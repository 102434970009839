import { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import { handleStoreReport } from 'services/api/reports'
import { handleCardRoles, handleCards } from 'services/api/reportCards'
import { handleUserShow } from 'services/api/users'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Checkbox from 'components/Form/LabelCheckbox'
import Select from 'components/Form/Select'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import { Context } from 'contexts/context'
import SelectDepartment from 'components/Form/SelectDepartment'
import { SelectLabel } from 'components/Form/styled'
import SelectOptions from 'components/Form/SelectOptions'
import SelectReport from 'components/Form/SelectReport'
import LoadingComponent from 'components/Loading'

const Page = () => {
  const params = useParams()

  const [cards, setCards] = useState([])
  const [user, setUser] = useState({})
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredCards, setFilteredCards] = useState([]); // New state for filtered cards
  const [reportRoles, setReportRoles] = useState([])
  const [loading, setLoading] = useState(false)

  const { user: { firm_id } } = useContext(Context)

  useEffect(() => {
    (async () => {
      setUser(await handleUserShow(params.id))
    })()
  }, [params.id])

  useEffect(() => {
    (async () => {
      try {
        setCards(await handleCards(firm_id))  
        setFilteredCards(await handleCards(firm_id))
      } catch(error) {}
    })()
  }, [firm_id])

  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const history = useHistory()
  const formik = useFormik({
    initialValues: { 
      user_id: params.id, roles: '', page_navigation: false, report_card_id: ''},
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: async () => {
      try {
        await handleStoreReport(history, formik.values, params.id, setMessages, setReportRoles)
      } catch (error) {
        console.log(error)
      }
    }
  })

  const breadcrumb = [
    { path: '/usuarios', label: 'Index' },
    { path: `/usuarios/${params.id}/relatorios`, label: 'Relatórios' }
  ]

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        // console.log(formik.values.card)
        if (formik.values.report_card_id !== null) {
        const card = (formik.values.report_card_id);

        if (card) {
          const roles = await handleCardRoles(card);
          setReportRoles(roles);
          formik.setFieldValue('roles', "");
      }
    }
    setLoading(false)
    } catch(error) {
      setLoading(false)
    }
    })();
  }, [formik.values.report_card_id])

  useEffect(() => {
    if (formik.values.report_card_id) {
      const selectedCard = filteredCards.find(c => c.id === formik.values.report_card_id);
    if (selectedCard) {
      formik.setFieldValue('report_name', selectedCard.name); // Atualiza o campo report_name com o nome do card
    } else {
      formik.setFieldValue('report_name', ''); // Limpa o campo se nenhum card estiver selecionado
    }
  }
  }, [formik.values.report_card_id]);
  
  return (
    <>
    {loading && <LoadingComponent />}
        {!loading && (
          <>
      <TitleBar label="Usuários" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Usuário" />
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Permissão</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.roles}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
        <Card>
          <CardTitle title="Cadastrar"></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />

                {firm_id === 11 && ( // Conditionally render SelectOptions
                  <SelectOptions 
                    label="Companhia"
                    name="company"
                    options={[
                      { value: null, label: 'Sem empresa' }, // Opção "Sem empresa"
                      ...[...new Set(cards.map(c => c.company_description).filter(description => description !== null))] // Remove duplicatas e ignora nulos
                        .map(description => ({
                          value: description, 
                          label: description
                        }))
                    ]}
                    onChange={(selectedOption) => {
                      const companyDescription = selectedOption ? selectedOption.value : null;
                      setSelectedCompany(companyDescription); // Atualiza o estado local
                      // Filter cards based on selected company
                      setFilteredCards(cards.filter(c => c.company_description === companyDescription));
                    }}
                  />
                )}

                <SelectReport 
                  name="report_card_id" 
                  label="Relatórios" 
                  options={filteredCards.map(c => ({
                    value: c.id,
                    label: (
                      <>
                        {c.name}
                        {c.company_description && (
                          <>
                            <span style={{ color: 'gray', margin: '0 10px' }}>
                              - 
                            </span>
                            <span style={{ color: 'gray' }}>
                              {c.company_description}
                            </span>
                          </>
                        )}
                      </>
                    )
                    ,
                    labelDisplay: `${c.name} ${c.company_description || ''}` // Campo para filtragem
                  }))}
                  formik={formik}
                />
                {/* <SelectDepartment
                  name="card"
                  label="Relatórios"
                  value=''
                  formik={formik}

                  options={cards.map((c) => ({ value: JSON.stringify(c), label: c.name }))}
                  /> */}
                <Select name="roles" label="Roles" formik={formik}
                    options={
                      reportRoles.map(role => ({ value: role, label: role })) 
                    }
                  />
                <Checkbox name='page_navigation' label='Habilitar Abas' formik={formik} checked={false} />
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
    )}
    </>
  )
}

export default Page